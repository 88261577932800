import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VirtualizedListBox from '../../shared/components/VirtualizedList/VirtualizedListBox';
import { AnswerOption } from './AnswerOption';
import './MultiSelect.scss';
import { MultiSelectProps } from './MultiSelectProps';

function MultiSelect(props: MultiSelectProps) {
  const { t } = useTranslation();
  const [selectedAnswerOptions, setSelectedAnswerOptions] = useState<AnswerOption[]>([]);

  useEffect(() => {
    if (!props.answerOptions || !props.selectedAnswerOptions) { return; }
    const selectedOptions = props.answerOptions.filter(el => props.selectedAnswerOptions.includes(el.id));
    setSelectedAnswerOptions(selectedOptions);
  }, [props.selectedAnswerOptions, props.answerOptions]);

  const handleChange = (event: SyntheticEvent, value: AnswerOption | null) => {
    if (!value) { return; }
    if(props.onAnswerOptionChange){
      props.onAnswerOptionChange(value);
    }
    setSelectedAnswerOptions(prev => {
      const hasAnswerOption = prev.find(el => el.id === value.id);
      const selectedAnswerOptions = hasAnswerOption ? prev : [...prev, value];
      if(props.onChange){
        props.onChange(selectedAnswerOptions);
      }
      return selectedAnswerOptions;
    });
  };

  const removeAnswerOption = (answerOption: AnswerOption) => {
    if(props.onAnswerOptionChange){
      props.onAnswerOptionChange(answerOption);
    }
    setSelectedAnswerOptions(prev => {
      const answerOptions = prev.filter(el => el.id !== answerOption.id);
      if(props.onChange){
        props.onChange(answerOptions);
      }
      return answerOptions;
    });
  }

  return (
    <Stack id='answer-options-multi-select'>
      <Box className='select-box'>
        <Autocomplete fullWidth
          disableListWrap
          ListboxComponent={VirtualizedListBox}
          options={props.answerOptions}
          renderInput={(params) => <TextField {...params} label={t(props.label)}/>}
          getOptionLabel={option => option.name}
          renderOption={(props, option, state) =>
            [props, option, state.index] as ReactNode
          }
          renderGroup={(params) => params as any}
          onChange={(event, value) => handleChange(event,value)}
        />
      </Box>
      <Stack>
        {
          selectedAnswerOptions.map(option => <Box className='selected-answer-option' key={option.name}>
              <Typography variant='body1'>{option.name}</Typography>
            <IconButton onClick={() => removeAnswerOption(option)}><CloseIcon/></IconButton>
            </Box>
          )
        }
      </Stack>
    </Stack>
  );
}

export default MultiSelect;
