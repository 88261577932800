import { Box } from '@material-ui/core';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Stack, TextField, Tooltip, Typography } from '@mui/material';
import SwalAlert from 'components/SwalAlert/SwalAlert';
import ClientQuestionnairesDTO from 'models/clientQuestionnaires/ClientQuestionnairesDTO';
import { groupBy } from 'lodash';
import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import CheckboxInput from '../../../components/FormFIelds/CheckboxInput';
import FileUploadInput from '../../../components/FormFIelds/FIleUploadInput';
import RadioInput from '../../../components/FormFIelds/RadioInput';
import { AnswerOption } from '../../../components/MultiSelect/AnswerOption';
import MultiSelect from '../../../components/MultiSelect/MultiSelect';
import { FileDTO } from '../../../models/aml/FileDTO';
import { FileHelper } from '../../../models/aml/FileHelper';
import AMLQuestionAnswerDTO from '../../../models/clientQuestionnaires/AMLQuestionAnswerDTO';
import AMLQuestionDTO from '../../../models/clientQuestionnaires/AMLQuestionDTO';
import AMLQuestionFileDTO from '../../../models/clientQuestionnaires/AMLQuestionFileDTO';
import { AnswersConstants } from '../../../models/clientQuestionnaires/constants/AnswersConstants';
import { QuestionType } from '../../../models/constants';
import ApplicationService from '../../../utils/ApplicationService';
import '../AMLProcedure.scss';
import './AMLQuestion.scss';

interface AMLQuestionProps {
  question: AMLQuestionDTO;
  type?: string;
  answers?: any;
  index?: number;
  description?: any;
  files?: any;
  register: any;
  watch: any;
  answerOnQuestion?: AMLQuestionAnswerDTO;
  sizeQuestion?: number;
  countries: AnswerOption[];
  onActionsDisabledChange: (actionsDisabled: boolean) => void;
  childrenQuestions: ClientQuestionnairesDTO[];
  updateChildrenAnswers?: (answer: AMLQuestionAnswerDTO) => void;
  removeChildAnswer?: (parentQuestionId: number, childQuestionId: number) => void;
  checkNextStep?: () => void;
}

export type AmlQuestionHandle = {
  getAmlQuestionAnswer: () => Promise<AMLQuestionAnswerDTO>
}

export const AMLQuestion = forwardRef<AmlQuestionHandle, AMLQuestionProps>(({
  question,
  type,
  answers,
  description,
  index,
  files,
  register,
  watch,
  answerOnQuestion,
  countries,
  onActionsDisabledChange,
  childrenQuestions,
  updateChildrenAnswers,
  removeChildAnswer,
  checkNextStep
}: AMLQuestionProps, ref) => {
  const [selectedRadioChoice, setSelectedRadioChoice] = useState<number | null>(null);
  const { t } = useTranslation();
  const [selectedFilesArray, setSelectedFilesArray] = useState<FileHelper[]>([]);
  const [selectedCheckBoxChoice, setSelectedCheckBoxChoice] = useState<number[]>([]);
  const [textAnswer, setTextAnswer] = useState<string | null>('');
  const [selectedCountries, setSelectedCountries] = useState<number[]>([]);
  const [childrenQuestionAnswers, setChildrenQuestionAnswers] = useState<AMLQuestionAnswerDTO[]>([]);
  const childrenQuestionAnswersRef = useRef<AMLQuestionAnswerDTO[]>([]); //we need this ref because when checking the values of the children fields we need the real condition of its state
  //the childrenQuestionAnswers state is one step behind because we are doing the update from the same function we are checking the state
  const [selectedDropdownAnswerOptions, setSelectedDropdownAnswerOptions] = useState<number[]>([]);

  useEffect(() => {
    if (answerOnQuestion) {
      if (type === QuestionType.MULTIPLE_CHOICE) {
        setSelectedCheckBoxChoice(answerOnQuestion.nomenclatureEntryIds);
        setTextAnswer(answerOnQuestion.freeTextAnswer);
      } else if (type === QuestionType.SINGLE_CHOICE) {
        setSelectedRadioChoice(answerOnQuestion.nomenclatureEntryIds[0]);
        setTextAnswer(answerOnQuestion.freeTextAnswer);
      } else if (type === QuestionType.FREE_TEXT || type === QuestionType.NUMBER) {
        setTextAnswer(answerOnQuestion.freeTextAnswer);
      } else if (type === QuestionType.DROPDOWN) {
        setSelectedDropdownAnswerOptions(answerOnQuestion.nomenclatureEntryIds);
        setTextAnswer(answerOnQuestion.freeTextAnswer);
      }
      if (answerOnQuestion.files) {
        const filesByIndex = groupBy(answerOnQuestion.files, 'index');
        const files = Object.keys(filesByIndex).reduce((acc, key) => {
          const files = filesByIndex[key].map(e => {
            const mimeType = 'application/octet-stream'; // Adjust MIME type if needed
            const blob = ApplicationService.base64ToBlob(
              e?.base64Content,
              mimeType
            );
            const fileName = e.name;
            return new File([blob], fileName, { type: mimeType });
          });
          return [...acc, { files: files, index: +key }];
        }, [] as FileHelper[]);
        setSelectedFilesArray(files);
      }
      if (answerOnQuestion.countries) {
        setSelectedCountries(answerOnQuestion.countries);
      }
    }
  }, []);

  const saveChildrenAnswers = (answer: AMLQuestionAnswerDTO) => {
    setChildrenQuestionAnswers((prev) => {
      const itemIndex = prev?.findIndex(
        (item) => item.amlQuestionId === answer.amlQuestionId
      );
      let updatedList;
      if (itemIndex !== -1) {
        updatedList = [...prev];
        updatedList[itemIndex] = answer;
      } else {
        updatedList = [...prev, answer];
      }

      // Update the ref as well
      childrenQuestionAnswersRef.current = updatedList;
      return updatedList;
    });
  };
  useEffect(() => {
    if (removeChildAnswer) {
      if (answerOnQuestion?.childrenQuestionAnswers) {
        const newChildrenQuestionAnswers = answerOnQuestion?.childrenQuestionAnswers; //from the list in the parent
        setChildrenQuestionAnswers(newChildrenQuestionAnswers!);
        childrenQuestionAnswersRef.current = newChildrenQuestionAnswers!;
      }

      childrenQuestions.map((q) => {
        if ((selectedCheckBoxChoice.length > 0 && !selectedCheckBoxChoice.includes(q?.connectedOnAnswerOption?.id))
          || (selectedRadioChoice !== null && selectedRadioChoice !== q?.connectedOnAnswerOption?.id) ||
          (selectedDropdownAnswerOptions.length > 0 && !selectedDropdownAnswerOptions.includes(q?.connectedOnAnswerOption?.id))) {
          removeChildAnswer(question.id, q.amlQuestionDTO.id); // from the overral object
          setChildrenQuestionAnswers(childrenQuestionAnswers.filter((item) => item.amlQuestionId !== q?.amlQuestionDTO?.id));
          childrenQuestionAnswersRef.current = childrenQuestionAnswers.filter((item) => item.amlQuestionId !== q?.amlQuestionDTO?.id);
        }
      });
    }
    if (updateChildrenAnswers) {
      getAmlQuestionAnswer().then((answer) => {
        updateChildrenAnswers(answer);
        if (checkNextStep) {
            checkNextStep();
        }
      })
    }
  }, [selectedRadioChoice, selectedCheckBoxChoice, selectedFilesArray, textAnswer, selectedCountries, selectedDropdownAnswerOptions]);

  useImperativeHandle(ref, () => {
    return {
      getAmlQuestionAnswer: () => {
        return getAmlQuestionAnswer();
      }
    };
  }, [selectedRadioChoice, selectedCheckBoxChoice, selectedFilesArray, textAnswer, selectedCountries, childrenQuestionAnswers, selectedDropdownAnswerOptions]);

  useEffect(() => {
    if (type === QuestionType.MULTIPLE_CHOICE) {
      if (selectedCheckBoxChoice?.length > 0) {
        onActionsDisabledChange(false);
      } else if (selectedCountries.length > 0) {
        onActionsDisabledChange(false);
      } else {
        onActionsDisabledChange(true);
      }
    } else if (type === QuestionType.SINGLE_CHOICE) {
      if (selectedRadioChoice != null) {
        onActionsDisabledChange(false);
      } else if (selectedCountries.length > 0) {
        onActionsDisabledChange(false);
      } else {
        onActionsDisabledChange(true);
      }
    } else if (type === QuestionType.FREE_TEXT || type === QuestionType.NUMBER) {
      if (textAnswer !== '') {
        onActionsDisabledChange(false);
      } else {
        onActionsDisabledChange(true);
      }
    } else if (type === QuestionType.DROPDOWN) {
      if (selectedDropdownAnswerOptions?.length > 0) {
        onActionsDisabledChange(false);
      } else {
        onActionsDisabledChange(true);
      }
    }
  }, [
    selectedRadioChoice,
    selectedFilesArray,
    selectedCheckBoxChoice,
    textAnswer,
    selectedCountries,
    selectedDropdownAnswerOptions
  ]);

  const validateNextStep = () => {
    for(let i=0; i<childrenQuestions.length; i++){
      const answer = childrenQuestionAnswersRef.current?.find((item) => item?.amlQuestionId === childrenQuestions[i].amlQuestionDTO.id);
      if (answer) {
        if (answer?.files?.length! > 0 || answer.nomenclatureEntryIds.length > 0 || answer.countries?.length! > 0 || (answer.freeTextAnswer !== null && answer.freeTextAnswer.trim() !== '')) {
          onActionsDisabledChange(false);
        } else {
          onActionsDisabledChange(true);
          break
        }
      }
    }
  };

  const handleFileChange = (files: File[], fileIndex: number) => {
    const MAX_FILE_SIZE = 10 * 1024 * 1024;
    const oversizedFiles = files.filter(file => file.size > MAX_FILE_SIZE);

    if (oversizedFiles.length > 0) {
      SwalAlert.errorAlert(t('FILE_TOO_LARGE'), t('FILE_SIZE_LIMIT', { size: '10 MB' }));
      return;
    }

    setSelectedFilesArray(prev => {
      const newList = prev.filter((e) => {
        return fileIndex !== e.index;
      });
      return [...newList, { files: files, index: fileIndex }];
    });
  };

  const handleRadioChange = (value: number) => {
    setSelectedRadioChoice(value);
  };

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTextAnswer(e.target.value);
  };

  const createAnswerCountries = (options: AnswerOption[]) => {
    setSelectedCountries(options.map(el => el.id));
  };

  const createAnswerAMLFileDTO = async () => {
    let filesDto: FileDTO[] = [];
    if (selectedFilesArray && selectedFilesArray.length > 0) {
      for (let i = 0; i < selectedFilesArray.length; i++) {
        for (let j = 0; j < selectedFilesArray[i].files.length; j++) {
          let base64FromFile: any = await ApplicationService.getBase64(
            selectedFilesArray[i].files[j]
          )
            .then((result) => {
              return result;
            })
            .catch(() => {
            });

          filesDto.push(
            new FileDTO({
              id: files && files.length > 0 ? files[selectedFilesArray[i].index].id : null,
              name: selectedFilesArray[i].files[j].name,
              mimeType: selectedFilesArray[i].files[j].type,
              base64Content: base64FromFile,
              index: selectedFilesArray[i].index
            })
          );
        }
      }
    }
    return filesDto ? filesDto : [];
  };

  const getAmlQuestionAnswer = async () => {
    const filesDto: FileDTO[] = await createAnswerAMLFileDTO();
    if (type === QuestionType.MULTIPLE_CHOICE) {
      return new AMLQuestionAnswerDTO(
        question.id,
        selectedCheckBoxChoice,
        textAnswer?.trim() === '' ? null : textAnswer,
        filesDto,
        selectedCountries,
        childrenQuestionAnswers
      );
    }
    if (type === QuestionType.SINGLE_CHOICE) {
      return new AMLQuestionAnswerDTO(
        question.id,
        selectedRadioChoice ? [selectedRadioChoice] : [],
        textAnswer?.trim() === '' ? null : textAnswer,
        filesDto,
        selectedCountries,
        childrenQuestionAnswers
      );
    }
    if (type === QuestionType.DROPDOWN) {
      return new AMLQuestionAnswerDTO(
        question.id,
        selectedDropdownAnswerOptions,
        textAnswer?.trim() === '' ? null : textAnswer,
        filesDto,
        selectedCountries,
        childrenQuestionAnswers
      );
    }
    return new AMLQuestionAnswerDTO(
      question.id,
      [],
      textAnswer?.trim() === '' ? null : textAnswer,
      filesDto,
      selectedCountries,
      childrenQuestionAnswers
    );
  };

  const onHandleSelectedCheckBoxChoice = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedCheckBoxChoice(
      selectedCheckBoxChoice.filter((element) => element === +e.target.value)
        .length > 0
        ? selectedCheckBoxChoice.filter((element) => element !== +e.target.value)
        : [...selectedCheckBoxChoice, +e.target.value]
    );
    handleOtherAnswerChange(+e.target.value, selectedCheckBoxChoice, setSelectedCheckBoxChoice);
  };

  const handleOtherAnswerChange = (
    selectedId: number,
    selectedAnswers: number[],
    setSelectedAnswers: React.Dispatch<React.SetStateAction<number[]>>
  ) => {
    if (answers.find((element: any) => element.keyName.toString() === AnswersConstants.OTHER) || answers.find((element: any) => element.keyName.toString() === AnswersConstants.ORG_OTHER)) {
      let chosenAnswer = answers.find((element: any) => element.id === selectedId);
      if (chosenAnswer?.keyName === AnswersConstants.OTHER || chosenAnswer?.keyName === AnswersConstants.ORG_OTHER) {
        if (selectedAnswers.find((element) => element === selectedId)) {
          setSelectedAnswers(selectedAnswers.filter((element) => element !== selectedId));
        } else {
          setSelectedAnswers([selectedId]);
        }
      } else {
        let otherAnswer = answers.find((element: any) => element.keyName.toString() === AnswersConstants.OTHER) || answers.find((element: any) => element.keyName.toString() === AnswersConstants.ORG_OTHER);
        let otherAnswerExistsSelected = selectedAnswers.find((element) => element === otherAnswer.id);
        if (otherAnswerExistsSelected) {
          setSelectedAnswers([...selectedAnswers.filter((element) => element !== otherAnswerExistsSelected), selectedId]);
          setTextAnswer('');
        }
      }
    }
  };

  const onAnswerOptionChange = (answerOption: AnswerOption) => {
    const value = answerOption?.id;
    setSelectedDropdownAnswerOptions(
      selectedDropdownAnswerOptions.filter((element) => element === value)
        .length > 0
        ? selectedDropdownAnswerOptions.filter((element) => element !== value)
        : [...selectedDropdownAnswerOptions, value]
    );
    handleOtherAnswerChange(value, selectedDropdownAnswerOptions, setSelectedDropdownAnswerOptions);
  };

  return (
    <>
      <Stack sx={{ justifyContent: 'space-between', flex: 1 }}>
        <Stack gap={1}>
          <Typography className='question-title'>
            <span className='question-order'>{(index ?? 0) + 1}</span> {question.title}
          </Typography>
          {description && <Typography>{description}</Typography>}
          {(type === QuestionType.FREE_TEXT) && (
            <TextField
              id={question.id}
              {...register(question.id.toString(), { required: true })}
              fullWidth={true}
              onChange={handleTextFieldChange}
            />)
          }
          {type === QuestionType.NUMBER && (
            <TextField
              id={question.id}
              type='number'
              {...register(question.id.toString(), { required: true })}
              fullWidth={true}
              onChange={handleTextFieldChange}
            />
          )}
          {type === QuestionType.MULTIPLE_CHOICE && (
            <div>
              {answers &&
                answers.length > 0 &&
                answers?.map((answer: any, index: number) => (
                  <div key={index}>
                    <Stack direction='row' alignItems={'center'}>
                      <CheckboxInput
                        label={answer?.name}
                        name={answer?.name?.substring(0, 10) + '' + index}
                        register={register}
                        value={answer.id}
                        isChecked={selectedCheckBoxChoice.includes(
                          answer.id
                        )}
                        watch={watch}
                        onChange={onHandleSelectedCheckBoxChoice}/>
                      {answer.description &&
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: answer.description }}/>}><InfoOutlined/></Tooltip>}
                    </Stack>
                  </div>
                ))}
            </div>
          )}
          {type === QuestionType.SINGLE_CHOICE && (
            <div>
              {answers &&
                answers.length > 0 &&
                answers?.map((answer: any, index: number) => (
                  <div key={index}>
                    <Stack direction='row' alignItems={'center'}>
                      <RadioInput
                        label={answer.name}
                        name={answer.name}
                        value={answer.id}
                        register={register}
                        selectedValue={selectedRadioChoice}
                        onChange={handleRadioChange}/>
                      {answer.description &&
                        <Tooltip color='primary' title={<div dangerouslySetInnerHTML={{ __html: answer.description }}/>}><InfoOutlined/></Tooltip>}
                    </Stack>
                  </div>
                ))}
            </div>
          )}
          {type === QuestionType.DROPDOWN &&
            <MultiSelect label={'PLEASE_CHOOSE_AN_OPTION'} key={question.id}
                         answerOptions={answers} selectedAnswerOptions={selectedDropdownAnswerOptions}
                         onAnswerOptionChange={onAnswerOptionChange}/>
          }
          {question?.useCountryList === true &&
            <MultiSelect label={'PLEASE_CHOOSE_AN_OPTION'} key={question.id}
                         answerOptions={countries} selectedAnswerOptions={selectedCountries}
                         onChange={createAnswerCountries}/>
          }
          {files?.map((file: AMLQuestionFileDTO, index: number) => {
            return (
              <Box key={index}>
                <div
                  key={index}
                  style={{ paddingBottom: '15px', paddingTop: '15x', maxWidth: '32rem' }}>
                  <FileUploadInput
                    label={file.title}
                    selectedFiles={selectedFilesArray?.find((e) => e.index === index)?.files!}
                    onFileChange={handleFileChange}
                    index={index}
                    onSizeErrorModal={(errorMessage: string) => {
                      Swal.fire({
                        icon: 'error',
                        title: t('FILE_TOO_LARGE')
                      });
                    }}
                    name={`file-${index}`}
                    register={register}
                    watch={watch}/>
                </div>
              </Box>
            );
          })}
          {childrenQuestions.map((question, childIndex) => {
            if (type === QuestionType.EMPTY_QUESTION || selectedCheckBoxChoice.includes(question?.connectedOnAnswerOption?.id)
              || selectedRadioChoice === question?.connectedOnAnswerOption?.id ||
              selectedDropdownAnswerOptions.includes(question?.connectedOnAnswerOption?.id)) {
              return (
                <div key={parseFloat(index + '.' + (childIndex + 1))}>
                  <AMLQuestion
                    question={question.amlQuestionDTO}
                    type={question?.amlQuestionDTO?.amlQuestionType}
                    index={parseFloat(index + '.' + (childIndex + 1))}
                    answers={question.answerOptions}
                    description={question.amlQuestionDTO.description}
                    files={question.amlQuestionFileDtos}
                    register={register}
                    watch={watch}
                    answerOnQuestion={answerOnQuestion?.childrenQuestionAnswers &&
                      answerOnQuestion?.childrenQuestionAnswers.find((item) => item.amlQuestionId == question.amlQuestionDTO.id)}
                    onActionsDisabledChange={onActionsDisabledChange}
                    countries={countries}
                    childrenQuestions={question.childrenQuestions}
                    updateChildrenAnswers={saveChildrenAnswers}
                    checkNextStep={validateNextStep}/>
                </div>
              );
            }
          })}
        </Stack>
      </Stack>
    </>
  );
});
