import axios from '../axios/axios';
import { CountryDto } from '../models/admin/CountryDto';
import { CountryFilter } from '../models/admin/CountryFilter';
import { AnswerOption } from '../components/MultiSelect/AnswerOption';
import { Page } from '../pagination/Page';

export class CountryService {

  static async getAllCountries() : Promise<AnswerOption[]> {
    const response =  await axios.get('/country/public/list-all');
    return response.data;
  }

  static async getAllPaged(
    page: number,
    size: number,
    name?: string
  ): Promise<Page<CountryDto>> {
    let filter: CountryFilter = { name: null };
    if (name) {
      filter.name = name;
    }

    const response = await axios.post<Page<CountryDto>>(
      `/country/public/all-paged`,
      filter,
      {
        params: { page, size }
      }
    );
    const data = response.data;
    return new Page<CountryDto>(
      data.content.map((country) => new CountryDto(country)),
      data.totalElements
    );
  }

  static async getById(id: number): Promise<CountryDto> {
    const response = await axios.get('/country/public/get/' + id);
    const countryDto = response.data;
    return new CountryDto(countryDto);
  }
}

export default CountryService;
