export const QuestionType = {
  FREE_TEXT: 'FREE_TEXT',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  SINGLE_CHOICE: 'SINGLE_CHOICE',
  NUMBER: 'NUMBER',
  DROPDOWN: 'DROPDOWN',
  EMPTY_QUESTION: 'EMPTY_QUESTION'
};


