import FileDTO from './FileDTO';

export default class AMLQuestionAnswerDTO {
  amlQuestionId: number | string;
  nomenclatureEntryIds: number[];
  freeTextAnswer: string | null;
  files: FileDTO[] | null;
  countries: number[] | null;
  childrenQuestionAnswers?: AMLQuestionAnswerDTO[];

  constructor(
    amlQuestionId: number,
    nomenclatureEntryIds: number[],
    freeTextAnswer: string | null,
    files: FileDTO[] | null,
    countries: number[] | null,
    childrenQuestionAnswers?: AMLQuestionAnswerDTO[]
  ) {
    this.amlQuestionId = amlQuestionId;
    this.nomenclatureEntryIds = nomenclatureEntryIds;
    this.freeTextAnswer = freeTextAnswer;
    this.files = files;
    this.countries = countries;
    this.childrenQuestionAnswers = childrenQuestionAnswers;
  }
}
