import React from 'react';
import { Radio, FormControlLabel, radioClasses } from '@mui/material';
import { UseFormRegister } from 'react-hook-form';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

interface RadioInputProps {
  label: string;
  name: string;
  value: number; // Use string type for value
  register: UseFormRegister<any>;
  selectedValue: number | null;
  onChange: (value: number) => void;
}

const RadioInput: React.FC<RadioInputProps> = ({ label, name, value, register, selectedValue, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = +event.target.value; // Get the value as a string
    onChange(newValue); // Pass the value as a string
  };

   const check=selectedValue == value


  return (
    <FormControlLabel
      control={
        <Radio
          {...register(name)}
          value={value}
          checked={check}
          sx={{
            [`&, &.${radioClasses.checked}`]: {
              color: '#0e387a',
            },
          }}
          icon={<RadioButtonUnchecked color={'primary'} />}
          checkedIcon={<RadioButtonChecked className="radioButtonCheckedIcon" />}
          onChange={handleChange}/>
      }
      label={<div className="radioButtonLabel" style={{ whiteSpace: 'nowrap' }}>{label}</div>}/>
  );
};

export default RadioInput;
